import React, { useEffect } from 'react'
import Layout from '../../layout'
import { graphql, Link } from "gatsby"
import "../../../styles/components/post/_post.scss";
import wrapMarkdownGatsbyImages from '../../../helpers/wrapMarkdownGatsbyImages';
import markdownLoadScripts from '../../../helpers/markdownLoadScripts';

export default function ProjectPost({data}) {

    const {date, technologies, title, description} = data.markdownRemark.frontmatter;
    const {slug} = data.markdownRemark.fields;
    const {html} = data.markdownRemark;

    useEffect(() => {

        wrapMarkdownGatsbyImages();
        markdownLoadScripts();

    }, []) 

    return (
        <Layout meta_title={title} meta_desc={description} meta_img={`/assets/metadata_images/generated/proj/${slug.replace(/\//g, "")}.png`}>
            <section className='post-frontmatter'>
                <div className="post-frontmatter__inner width-restricted-container">
                    <div className='post-frontmatter__breadcrumbs'>
                        <Link to='/'>Home</Link> {'>'} <Link to='/projects'>Projects</Link>
                    </div>
                    <h1 className='post-frontmatter__title'>
                        {title}
                    </h1>
                    <div className='post-frontmatter__date'>Posted on <span>{date}</span> by <Link to='/about'>Jonny Mars</Link></div>
                    <div className={`post-frontmatter__used ${technologies.length === 0 ? 'post-frontmatter__used--inactive' : ''}`}>
                        <div className='post-frontmatter__used__title'>
                            Used in this project
                        </div>
                        <div className='post-frontmatter__used__list flex-container'>
                            {technologies.map((item, i) => {
                                return <span key={i}>{item}</span>
                            })}
                        </div>
                    </div> 
                </div>
            </section>
                <section className='post-content'>
                <div
                className="post-content__inner width-restricted-container"
                dangerouslySetInnerHTML={{ __html: html }}
                />
            </section>
        </Layout>
    )
}


export const ProjectPostTemplateQuery = graphql`
  query ProjectPostTemplateQuery($slug: String) {
    markdownRemark(
        collection: {eq: "project"}, 
        fields: {slug: {eq: $slug}}
        ) {
      frontmatter {
        date(formatString: "MMM D, YYYY")
        description
        technologies
        title
      }
      html
      fields {
        slug
      }
    }
  }
`