export default () => {
    let markdown_post_content = document.querySelector(`.post-content`);

    if(!markdown_post_content) return;

    let scripts = markdown_post_content.querySelectorAll(`script`);

    if(scripts.length === 0) return;

    scripts.forEach(script => {

        let head = document.head;
        let body = document.body;

        let src = script.getAttribute("src");
        let is_async = script.getAttribute("async") ? true : false;
        let script_content = script.innerHTML;

        let new_script = document.createElement("script");
        if(is_async) new_script.setAttribute("async", "");
        if(src) new_script.src = src;
        new_script.innerHTML = script_content;


        if (is_async) head.appendChild(new_script);
        else body.appendChild(new_script);

        

    })
}
