export default () => {

    let markdown_post_content = document.querySelector(`.post-content`);

    if(!markdown_post_content) return;

    let all_images = markdown_post_content.querySelectorAll(".gatsby-resp-image-wrapper, img[src$='.gif']");

    if(all_images.length === 0) return;

    all_images.forEach(img => {

        let wrapper = document.createElement('div');
        wrapper.className = 'gatsby-resp-image-fullwidth-wrapper';

        img.insertAdjacentElement(`beforebegin`, wrapper);

        wrapper.appendChild(img);

    })
    
}
